var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-form',{ref:"formBox",attrs:{"formBuilder":_vm.formBuilder},scopedSlots:_vm._u([{key:"fileIds",fn:function({ record }){return _c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        record.model,
        {
          rules: record.rules,
        },
      ]),expression:"[\n        record.model,\n        {\n          rules: record.rules,\n        },\n      ]"}]},[_c('imageUpload',{ref:"img"})],1)}}])},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options',
          { rules: [{ required: true, message: '请选择' }] },
        ]),expression:"[\n          'options',\n          { rules: [{ required: true, message: '请选择' }] },\n        ]"}],attrs:{"options":_vm.options,"placeholder":"请选择所属网格","change-on-select":"","show-search":{ filter: _vm.filter },"allowClear":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666},on:{"change":_vm.onChange}})],1),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'myContent',
        {
          rules: [{ required: true, message: '正文不能为空' }],
        },
      ]),expression:"[\n        'myContent',\n        {\n          rules: [{ required: true, message: '正文不能为空' }],\n        },\n      ]"}],attrs:{"slot":"introContent"},slot:"introContent"},[_c('wangeditor',{attrs:{"content":_vm.myContent},on:{"onChange":_vm.quillHtml}})],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.closeDialog}},[_vm._v(" 取消 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }